import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type EyeIconProps
 */
export type EyeIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const EyeIcon
 */
const EyeIcon = (props: EyeIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="16.7" ref={ ref } viewBox="0 0 27.099 16.7" width="27.099">
            <path d="M 13.539062 0 C 7.9725537 0.07647153 2.8725555 3.1360794 0.18554688 8.0117188 L 0 8.3496094 L 0.18554688 8.6875 C 2.8725555 13.563139 7.9725537 16.622747 13.539062 16.699219 L 13.548828 16.699219 L 13.558594 16.699219 C 19.125035 16.622747 24.225101 13.563139 26.912109 8.6875 L 27.097656 8.3496094 L 26.912109 8.0117188 C 24.225101 3.1360794 19.125103 0.07647153 13.558594 0 L 13.548828 0 L 13.539062 0 z M 13.548828 1.4023438 C 18.46777 1.473437 22.942341 4.1320437 25.435547 8.3496094 C 22.94236 12.567266 18.467865 15.227734 13.548828 15.298828 C 8.6297917 15.227734 4.1552965 12.567266 1.6621094 8.3496094 C 4.1553153 4.1320437 8.629852 1.473437 13.548828 1.4023438 z M 13.798828 4.3007812 C 11.258669 4.3007811 9.0996094 6.1346512 9.0996094 8.5 C 9.0996094 10.865349 11.258669 12.699219 13.798828 12.699219 C 16.338987 12.699219 18.498047 10.865349 18.498047 8.5 C 18.498047 6.1346513 16.338987 4.3007812 13.798828 4.3007812 z M 13.798828 5.6992188 C 15.676948 5.6992188 17.099609 6.9993555 17.099609 8.5 C 17.099609 10.000645 15.676948 11.300781 13.798828 11.300781 C 11.920709 11.300781 10.498047 10.000645 10.498047 8.5 C 10.498047 6.9993554 11.920709 5.6992186 13.798828 5.6992188 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(EyeIcon);
