import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { Ref } from "react";

import clsx from "clsx";

import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import GlassesAltIcon from "@Js/Component/Icon/GlassesAltIcon";

import * as classNames from "@Css/Component/ReadingTime.module.scss";

/**
 * @type ReadingTimeProps
 */
export type ReadingTimeProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children"> & {
    content: string;
};

/**
 * @const ReadingTime
 */
const ReadingTime = (props: ReadingTimeProps, ref: Ref<HTMLDivElement>) => {
    const {
        className,
        content,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const wordsPerMinute = 200;
    const wordCount = content.split(" ").length;
    const estimatedReadingTime = Math.round(wordCount / wordsPerMinute);

    return (
        <div { ...restProps } className={ clsx(classNames.readingTime, className) } ref={ ref }>
            <GlassesAltIcon className={ classNames.icon } />
            <span>{ t("Reading Time") }: { estimatedReadingTime } min.</span>
        </div>
    );
}

export default forwardRef(ReadingTime);
