import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type GlassesAltIconProps
 */
export type GlassesAltIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const GlassesAltIcon
 */
const GlassesAltIcon = (props: GlassesAltIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="11.400" ref={ ref } viewBox="0 0 26.400 11.400" width="26.400">
            <path d="M20.7,0A5.71,5.71,0,0,0,15,5.29H11.38a5.67,5.67,0,1,0,0,1H15A5.7,5.7,0,1,0,20.7,0ZM5.7,10.4a4.7,4.7,0,1,1,4.7-4.7A4.71,4.71,0,0,1,5.7,10.4Zm15,0a4.7,4.7,0,1,1,4.7-4.7A4.71,4.71,0,0,1,20.7,10.4Z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(GlassesAltIcon);
