import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { IGatsbyImageData } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { PageProps } from "gatsby";
import { Ref } from "react";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useContext } from "react";
import { useLayoutEffect } from "react";

import ThemeContext from "@Js/Context/ThemeContext";

import Seo from "@Js/Component/Seo";

import EyeIcon from "@Js/Component/Icon/EyeIcon";
import Gutenberg from "@Js/Component/Gutenberg";
import Heading from "@Js/Component/Heading";
import ReadingTime from "@Js/Component/ReadingTime";

import * as classNames from "@Css/Component/Page/Blogs.module.scss";

/**
 * @type AllWordpressPostData
 */
type AllWordpressPostData = {
    allWordpressPost: {
        edges: {
            node: {
                content?: string;
                id: string;
                postDetails: {
                    employee?: {
                        featuredImage?: {
                            node: {
                                localFile: {
                                    childImageSharp: {
                                        gatsbyImageData: IGatsbyImageData;
                                    };
                                };
                            };
                        };
                    };
                    thumbnail?: {
                        localFile: {
                            childImageSharp: {
                                gatsbyImageData: IGatsbyImageData;
                            };
                        };
                    };
                };
                title: string;
                uri: string;
            };
        }[];
    };
};

/**
 * @type WordpressPageData
 */
type WordpressPageData = {
    wordpressPage: {
        content?: string;
        extraContent?: string;
        seo: {
            canonical: string;
            metaDesc: string;
            metaKeywords: string;
            metaRobotsNofollow: string;
            metaRobotsNoindex: string;
            opengraphAuthor: string;
            opengraphDescription: string;
            opengraphModifiedTime: string;
            opengraphPublishedTime: string;
            opengraphPublisher: string;
            opengraphSiteName: string;
            opengraphTitle: string;
            opengraphType: string;
            opengraphUrl: string;
            schema: {
                raw: string;
            };
            title: string;
        };
    };
};

/**
 * @type BlogsProps
 */
export type BlogsProps = PageProps;

/**
 * @const Blogs
 */
const Blogs = (props: BlogsProps, ref: Ref<HTMLElement>) => {
    const {
        setHeaderBackground,
        setIsLogoColorless,
        setIsMenuColorless
    } = useContext(ThemeContext);

    const data: AllWordpressPostData & WordpressPageData = useStaticQuery(graphql`
        query {
            allWordpressPost(sort: { fields: date, order: DESC }) {
                edges {
                    node {
                        content
                        id
                        postDetails {
                            employee {
                                 ... on WordpressEmployee {
                                    featuredImage {
                                        node {
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(height: 100, width: 100, quality: 100, transformOptions: { cropFocus: NORTH })
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            thumbnail {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(quality: 100)
                                    }
                                }
                            }
                        }
                        title
                        uri
                    }
                }
            }
            wordpressPage(isBlogsPage: { eq: true }) {
                content
                extraContent
                seo {
                    canonical
                    metaDesc
                    metaKeywords
                    metaRobotsNofollow
                    metaRobotsNoindex
                    opengraphAuthor
                    opengraphDescription
                    opengraphModifiedTime
                    opengraphPublishedTime
                    opengraphPublisher
                    opengraphSiteName
                    opengraphTitle
                    opengraphType
                    opengraphUrl
                    schema {
                        raw
                    }
                    title
                }
            }
        }
    `);

    useLayoutEffect(() => {
        setHeaderBackground("#FFFFFF");

        setIsLogoColorless(false);
        setIsMenuColorless(false);
    }, []);

    return (
        <>
            <Seo { ...data.wordpressPage.seo } />
            <section className={ classNames.blogs } ref={ ref }>
                { data.wordpressPage.content && (
                    <Gutenberg className={ classNames.gutenberg } content={ data.wordpressPage.content } />
                ) }
                <section className={ classNames.blogs }>
                    <div className={ classNames.container }>
                        { data.allWordpressPost.edges.length > 0 && (
                            <ul className={ classNames.list }>
                                { data.allWordpressPost.edges.map((edge) => (
                                    <li className={ classNames.listItem } key={ edge.node.id }>
                                        <Link className={ classNames.link } to={ `/blog${ edge.node.uri }` }>
                                            { edge.node.postDetails.thumbnail && (
                                                <GatsbyImage
                                                    alt={ edge.node.title }
                                                    className={ classNames.image }
                                                    image={ edge.node.postDetails.thumbnail.localFile.childImageSharp.gatsbyImageData }
                                                />
                                            ) }
                                            <div className={ classNames.overlay } />
                                            <section className={ classNames.container }>
                                                <Heading className={ classNames.heading }>{ edge.node.title }</Heading>
                                                <ReadingTime className={ classNames.readingTime } content={ edge.node.content || "" } />
                                            </section>
                                            { edge.node.postDetails.employee && edge.node.postDetails.employee.featuredImage && (
                                                <GatsbyImage
                                                    alt={ edge.node.title }
                                                    className={ classNames.employeeImage }
                                                    image={ edge.node.postDetails.employee.featuredImage.node.localFile.childImageSharp.gatsbyImageData }
                                                />
                                            ) }
                                            <div className={ classNames.iconWrapper }>
                                                <EyeIcon className={ classNames.icon } />
                                            </div>
                                        </Link>
                                    </li>
                                )) }
                            </ul>
                        ) }
                    </div>
                </section>
            </section>
        </>
    );
};

export default forwardRef(Blogs);
