// extracted by mini-css-extract-plugin
export var blogs = "Blogs-module--blogs--1PV9n";
export var gutenberg = "Blogs-module--gutenberg--2KT_D";
export var container = "Blogs-module--container--30PTg";
export var list = "Blogs-module--list--OwkHl";
export var listItem = "Blogs-module--listItem--Bej8n";
export var link = "Blogs-module--link--1ShP1";
export var imageWrapper = "Blogs-module--imageWrapper--18Oe8";
export var iconWrapper = "Blogs-module--iconWrapper--3LiLG";
export var image = "Blogs-module--image--1P4g_";
export var overlay = "Blogs-module--overlay--3OPdG";
export var heading = "Blogs-module--heading--3FGkM";
export var readingTime = "Blogs-module--readingTime--2SAg8";
export var employeeImage = "Blogs-module--employeeImage--2jPlE";
export var icon = "Blogs-module--icon--LFU2L";
export var buttonWrapper = "Blogs-module--buttonWrapper--1LQ3f";
export var bounce = "Blogs-module--bounce--Tsf5J";
export var heartbeat = "Blogs-module--heartbeat--1rQDe";
export var ellipsisOne = "Blogs-module--ellipsisOne--2wIqW";
export var ellipsisTwo = "Blogs-module--ellipsisTwo--3gu_w";
export var ellipsisThree = "Blogs-module--ellipsisThree--1tkdv";
export var top = "Blogs-module--top--2izGK";
export var topTwo = "Blogs-module--topTwo--1rVOi";
export var center = "Blogs-module--center--245Vf";
export var centerTwo = "Blogs-module--centerTwo--3tzqP";
export var bottom = "Blogs-module--bottom--2cSGu";
export var bottomTwo = "Blogs-module--bottomTwo--1fykS";